import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Q_rtfLaoAjY">
    <SEO title="Jesus and our Heroes - Hebrews" />
  </Layout>
)

export default SermonPost
